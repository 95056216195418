import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6cb71c64"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "digital-custom"
};
import UniRecommendBanner from "@/views/digitalCustom/uni-recommend-module/uni-recommend-banner.vue";
import ProductGuide from "@/views/digitalCustom/uni-recommend-module/product-guide.vue";
export default {
  __name: 'index',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(UniRecommendBanner), _createVNode(ProductGuide)]);
    };
  }
};